import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeaderCategory from "../components/PageCategory/Header"
import ContactFormSection from "../components/ContactFormSection"
import DynamicZone from "../components/DynamicZone"
import SpotlightProjet from "../components/PageCategory/SpotlightProjet"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { datoCmsCategory } = data // data.mdx holds your post data

  return (
    <Layout>
      <HeaderCategory
        titre={datoCmsCategory.title}
        texte={datoCmsCategory.descriptionCourte}
        color={datoCmsCategory.color.hex}
        json={datoCmsCategory.lottiejson.url}
        svg={datoCmsCategory.svg}
        illustration={datoCmsCategory.illustration.gatsbyImageData}
      />
      {datoCmsCategory.projetSpotlight && (
        <SpotlightProjet
          image={datoCmsCategory.projetSpotlight.illustration.gatsbyImageData}
          title={datoCmsCategory.projetSpotlight.titre}
          slug={datoCmsCategory.projetSpotlight.slug}
          color={datoCmsCategory.color.hex}
        />
      )}
      {datoCmsCategory.contenu && (
        <DynamicZone content={datoCmsCategory.contenu} />
      )}
      <ContactFormSection />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    datoCmsCategory(slug: { eq: $slug }) {
      lottiejson {
        isImage
        url
      }
      title
      titreLigne1
      titreLigne2
      descriptionCourte
      color {
        hex
      }
      projetSpotlight {
        titre
        slug
        sousTitre
        illustration {
          gatsbyImageData
        }
      }
      illustration {
        gatsbyImageData
      }
      contenu {
        __typename
        ... on DatoCmsImageSBlock {
          imageS {
            image {
              gatsbyImageData
            }
          }
        }
        ... on DatoCmsVideoBlock {
          videos {
            id
            urlYoutube {
              url
              provider
              height
              providerUid
              thumbnailUrl
              title
              width
            }
            titreDeLaVidO
          }
          titre
          description
          videoDAbord
        }
      }
    }
  }
`

export const Head = ({ data, location }) => {
  const { datoCmsCategory } = data // data.mdx holds your post data
  return (
    <Seo
      title={datoCmsCategory.title}
      description={datoCmsCategory.descriptionCourte}
      lang={"fr"}
      canonical={location.pathname}
    />
  )
}
